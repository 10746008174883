import { GetSelectLookup } from "./common";

const CoughEnum = {
  None: 0,
  Dry: 1,
  Wet: 2,
  Wheeze: 3,
  Stridor: 4
};

const Lookup = ["None", "Dry", "Wet", "Wheeze", "Stridor"];

export default {
  Enum: CoughEnum,
  SelectLookup: GetSelectLookup(CoughEnum, Lookup),
  Lookup: Lookup
};
