<template>
  <XyeaFormEdit
    icon="notebook-edit"
    :url="isResident ? 'residentCarePlans/' : 'careplans/'"
    :entityName="isResident ? 'Resident Care Plan' : 'Care Plan'"
    backRoute="careplans"
    :form="form"
    :id="id"
    @close="$emit('close')"
    @dataSaved="dataSaved"
    @mapServerData="mapServerData"
    :dialog="isResident"
    :isWideDialog="isResident"
  >
    <div v-if="historyView">
      <div class="columns">
        <div class="column">
          <ContextTableCard
            title="History"
            icon="history"
            :tableData="tableData"
            :header-icons="headerIcons"
            :sortField="search.sortField"
            :sortOrder="search.sortOrder"
            :page="search.page"
            :total="total"
            @refreshData="debounceRefreshData"
            @page-change="pageChange"
            @sort="sort"
            v-model="selected"
            :isLoading="isLoading"
            :noMenu="true"
          >
            <b-table-column label="Due" field="modifiedDate" sortable v-slot="props">
              {{ shortFormatDateTime(props.row.modifiedDateLocal) }}
            </b-table-column>
            <b-table-column label="Who" field="who" sortable v-slot="props">
              {{ props.row.who }}
            </b-table-column>

            <b-table-column label="Change" field="summary" sortable v-slot="props">
              {{ props.row.summary }}
            </b-table-column>
          </ContextTableCard>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <div v-else>
      <b-field label="Name" horizontal>
        <b-input v-model="form.name" required />
        <ClickIcon v-if="id" icon="history" title="History" @click="showHistory" tooltipPosition="is-left" />
      </b-field>
      <b-field label="Goal" horizontal>
        <b-input v-model="form.goal" required />
        <ClickIcon icon="add" title="Add New Section" @click="addSection" tooltipPosition="is-left" />
      </b-field>
      <hr class="clearfix" />
      <template v-for="section in form.sections">
        <div class="columns" :key="section.carePlanSectionId">
          <div class="column sectionHeader">
            <b-input v-model="section.header" required :ref="'h' + section.carePlanSectionId" />
          </div>
          <div class="column is-1">
            <ClickIcon icon="add" title="Add New Item" @click="addItem(section)" />
            <ClickIcon icon="delete" title="Delete Section" @click="deleteSection(section)" />
          </div>
        </div>
        <div class="pb-5" v-for="item in section.items" :key="'i' + item.carePlanItemId">
          <FieldTypeSelector
            v-for="(risk, i) in item.riskInterventions"
            :key="'ri' + risk.riskInterventionId"
            v-model="item.riskInterventions[i]"
            :i="i"
            :ref="'f' + risk.riskInterventionId"
            :selectRef="'f' + risk.riskInterventionId"
            @addRisk="addRisk(item)"
            @deletItem="deleteItem(section, item)"
            @deleteRisk="deleteRisk(item, risk)"
          />
          <div class="mt-3">
            <template v-for="(intervention, j) in item.interventions">
              <InterventionEdit
                v-model="item.interventions[j]"
                :value="intervention"
                :i="j"
                @add="addIntervention(item)"
                @delete="deleteIntervention(item, intervention)"
                :key="'i' + intervention.interventionId"
                :ref="'i' + intervention.interventionId"
              />
            </template>
          </div>
        </div>
      </template>
    </div>
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import FieldTypeSelector from "@/components/careplans/FieldTypeSelector";
import InterventionEdit from "@/components/careplans/InterventionEdit";
import CompletionType from "@/enums/completionType";
import CheckDirty from "@/mixins/checkDirty";
import ClickIcon from "@/components/ClickIcon";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";

export default {
  mixins: [CheckDirty, ContextTableMixin],
  components: {
    XyeaFormEdit,
    FieldTypeSelector,
    InterventionEdit,
    ClickIcon,
    ContextTableCard
  },
  props: {
    id: { type: [String, Number], default: null },
    isResident: { type: Boolean }
  },
  data() {
    return {
      newId: -1,
      form: {
        name: null,
        goal: null,
        sections: [this.newSection()]
      },
      historyView: false,

      url: "carePlanHistory",
      entity: "History",
      search: {
        page: 1,
        sortField: "modifiedDate",
        sortOrder: "desc",
        carePlanId: this.isResident ? null : this.id,
        residentCarePlanId: this.isResident ? this.id : null,
        openOnly: true
      }
    };
  },
  methods: {
    validateForm() {
      return true;
    },

    mapServerData(data) {
      this.form = data; // map top level params
      this.setNotDirty();
    },
    addSection() {
      const newSection = this.newSection();
      this.form.sections.push(newSection);
      if (newSection.carePlanSectionId < 0) {
        this.setFocus("h" + newSection.carePlanSectionId);
      }
    },
    newSection() {
      this.newId ??= 0; // initial call does not have newId setup yet
      return {
        carePlanSectionId: this.newId--,
        header: null,
        items: [this.newItem()]
      };
    },
    deleteSection(section) {
      this.form.sections = this.form.sections.filter(v => v !== section);
    },
    addItem(section) {
      const newItem = this.newItem();
      section.items.push(newItem);
      this.setFocus("i" + newItem.carePlanItemId);
    },
    newItem() {
      return {
        carePlanItemId: this.newId--,
        itemType: null,
        assessmentType: 0,
        riskInterventions: [this.newRisk()],
        interventions: [this.newIntervention()]
      };
    },
    addRisk(item) {
      const newRisk = this.newRisk();
      item.riskInterventions.push(newRisk);
      this.setFocus("r" + newRisk.riskInterventionId);
    },
    deleteItem(section, item) {
      section.items = section.items.filter(v => v !== item);
    },
    deleteRisk(item, risk) {
      item.riskInterventions = item.riskInterventions.filter(v => v !== risk);
    },
    deleteIntervention(item, intervention) {
      item.interventions = item.interventions.filter(v => v !== intervention);
    },
    newRisk() {
      return {
        riskInterventionId: this.newId--,
        risk: null,
        interventions: [this.newIntervention()],
        questions: []
      };
    },
    newIntervention() {
      return {
        interventionId: this.newId--,
        intervention: null,
        role: null,
        frequency: null,
        timeOfDay1: null,
        timeOfDay2: null,
        timeOfDay3: null,
        completionType: CompletionType.Enum.Manual,
        completionAssessment: null,
        completionObservation: null
      };
    },
    addIntervention(item) {
      const newIntervention = this.newIntervention();
      item.interventions.push(newIntervention);
      this.setFocus("i" + newIntervention.interventionId);
    },
    setFocus(field) {
      const refs = this.$refs;
      setTimeout(() => {
        let ref = refs[field];
        let focus = ref ? ref[0].focus : null;

        if (focus) focus();
        else {
          ref = ref ? ref[0].$refs[field] : null;
          if (ref) {
            focus = ref.focus;
            if (focus) focus();
          }
        }
      }, 100);
    },
    dataSaved() {
      // Data Saved, so clear dirty flag
      this.setNotDirty(true);
      this.$emit("refresh");
    },
    showHistory() {
      this.historyView = !this.historyView;
    }
  }
};
</script>

<style>
.carePlanItemHeader {
  /* needed neg margin to fix spacing */
  margin-bottom: -1rem !important;
}
.carePlanItemHeader .columns {
  padding-top: 0;
  padding-bottom: 0;
}
.interventions {
  margin-bottom: 2rem;
}
</style>
