const IntimateCareEnum = {
  NA: 0,
  WithoutHelp: 1,
  Verbal: 2,
  VerbalAndPhysical: 3,
  Supervised: 4
};

const IntimateCareLookup = [
  "Not Applicable",
  "I can do this without help",
  "I need verbal assistance.",
  "I need verbal and physical assistance",
  "I need supervision / reminding only"
];

export default {
  Enum: IntimateCareEnum,
  SelectLookup: [
    { id: IntimateCareEnum.WithoutHelp, value: IntimateCareLookup[IntimateCareEnum.WithoutHelp] },
    { id: IntimateCareEnum.Verbal, value: IntimateCareLookup[IntimateCareEnum.Verbal] },
    { id: IntimateCareEnum.VerbalAndPhysical, value: IntimateCareLookup[IntimateCareEnum.VerbalAndPhysical] },
    { id: IntimateCareEnum.Supervised, value: IntimateCareLookup[IntimateCareEnum.Supervised] },
    { id: IntimateCareEnum.NA, value: IntimateCareLookup[IntimateCareEnum.NA] }
  ],
  Lookup: IntimateCareLookup
};
