<template>
  <div class="columns narrow">
    <template v-if="isSingleLine">
      <div class="column is-1">
        <div class="level-item has-text-centered" v-if="i === 0">
          <p class="label">Interventions</p>
          <ClickIcon icon="add" title="Add New Intervention" @click="$emit('add')" />
        </div>
      </div>
      <div class="column">
        <b-input
          :value="value.intervention"
          @input="v => update('intervention', v)"
          :ref="'i' + value.interventionId"
          placeholder="Description"
          required
          :disabled="interventionReadonly"
        />
      </div>
      <div class="column">
        <RoleSelect
          class="level-item is-flex-grow-1"
          :value="value.roleId"
          @input="v => update('roleId', v)"
          required
          :disabled="interventionReadonly"
          :editTopLevel="userRoleLevel"
        />
      </div>
      <div class="column">
        <EnumDropDown
          :value="value.frequency"
          @input="v => update('frequency', v)"
          :data="frequency.SelectLookup"
          required
          :disabled="interventionReadonly"
          placeholder="<Frequency>"
        />
      </div>
      <div class="column">
        <FreqTimeOfDay
          v-if="showTimeOFDay"
          class="level-item"
          :value="value"
          @input="updateKey"
          :disabled="interventionReadonly"
          :frequency="value.frequency"
        />
      </div>
      <div class="column">
        <CompletionTrigger :value="value" @input="updateKey" :disabled="interventionReadonly" />
      </div>
      <div class="level-item has-text-centered">
        <ClickIcon
          v-if="!interventionReadonly"
          :disabled="i === 0"
          icon="delete"
          title="Delete Intervention"
          @click="$emit('delete')"
          tooltipPosition="is-left"
        />
      </div>
    </template>
    <template v-else>
      <b-field label="Intervention" horizontal>
        <b-input
          :value="value.intervention"
          @input="v => update('intervention', v)"
          placeholder="Description"
          required
          type="textarea"
          :disabled="interventionReadonly"
        />
      </b-field>
      <b-field label="Role" horizontal>
        <RoleSelect
          class="level-item is-flex-grow-1"
          :value="value.roleId"
          @input="v => update('roleId', v)"
          required
          :disabled="interventionReadonly"
          :editTopLevel="userRoleLevel"
        />
      </b-field>
      <b-field label="Frequency" horizontal>
        <EnumDropDown
          :value="value.frequency"
          @input="v => update('frequency', v)"
          :data="frequency.SelectLookup"
          required
          placeholder="<Frequency>"
          :disabled="interventionReadonly"
      /></b-field>
      <b-field label="Time" horizontal v-if="showTimeOFDay">
        <FreqTimeOfDay :value="value" @input="updateKey" :disabled="interventionReadonly" :frequency="value.frequency" />
      </b-field>
      <b-field label="Completion" horizontal class="field-nested">
        <CompletionTrigger :value="value" @input="updateKey" :disabled="interventionReadonly" />
      </b-field>
    </template>
  </div>
</template>

<script>
import EnumDropDown from "@/components/EnumDropDown";
import RoleSelect from "@/components/RoleSelect";
import FrequencyEnum from "@/enums/frequency";
import CompletionTrigger from "./CompletionTrigger.vue";
import FreqTimeOfDay from "./FreqTimeOfDay.vue";
import OrgConfig from "@/enums/orgconfig";
import { mapGetters } from "vuex";
import ClickIcon from "@/components/ClickIcon";

export default {
  components: {
    EnumDropDown,
    RoleSelect,
    FreqTimeOfDay,
    CompletionTrigger,
    ClickIcon
  },
  props: {
    // { intervention, roleId, frequency, timeOfDay1, timeOfDay2, timeOfDay3, completionType, completionAssessment, completionObservation }
    value: { type: Object, default: () => {} },
    isSingleLine: { type: Boolean, default: true },
    readonly: { type: Boolean },
    i: { type: Number } // index
  },
  data() {
    return {
      frequency: FrequencyEnum
    };
  },
  computed: {
    ...mapGetters("orgconfig", ["defaults"]),
    ...mapGetters("roles", ["roleLevel"]),
    ...mapGetters(["userRoleLevel"]),

    showTimeOFDay() {
      return [
        FrequencyEnum.Enum.OneOff,
        FrequencyEnum.Enum.OnceAMonth,
        FrequencyEnum.Enum.OnceAWeek,
        FrequencyEnum.Enum.TwiceAWeek,
        FrequencyEnum.Enum.OnceADay,
        FrequencyEnum.Enum.TwiceADay,
        FrequencyEnum.Enum.ThreeTimesADay
      ].includes(this.value.frequency);
    },
    interventionReadonly() {
      // depends on overall readonly state and also the current user's role
      return this.readonly || !this.userRoleLevel || this.userRoleLevel < this.roleLevel(this.value.roleId);
    }
  },
  watch: {
    "value.frequency": {
      handler(f) {
        // set defaults
        switch (f) {
          case FrequencyEnum.Enum.OneOff:
            this.update("timeOfDay1", this.defaults[OrgConfig.Enum.Freq_Default_OneOff].value);
            break;
          case FrequencyEnum.Enum.OnceAMonth:
            this.update("timeOfDay1", this.defaults[OrgConfig.Enum.Freq_Default_OnceAMonth].value);
            break;
          case FrequencyEnum.Enum.OnceAWeek:
            this.update("timeOfDay1", this.defaults[OrgConfig.Enum.Freq_Default_OnceAWeek].value);
            break;
          case FrequencyEnum.Enum.TwiceAWeek:
            this.updateMultiple({
              timeOfDay1: this.defaults[OrgConfig.Enum.Freq_Default_TwiceAWeek].value,
              timeOfDay2: this.defaults[OrgConfig.Enum.Freq_Default_TwiceAWeek].value2
            });
            break;
          case FrequencyEnum.Enum.OnceADay:
            this.update("timeOfDay1", this.defaults[OrgConfig.Enum.Freq_Default_OnceADay].value);
            break;
          case FrequencyEnum.Enum.TwiceADay:
            this.updateMultiple({
              timeOfDay1: this.defaults[OrgConfig.Enum.Freq_Default_TwiceADay].value,
              timeOfDay2: this.defaults[OrgConfig.Enum.Freq_Default_TwiceADay].value2
            });
            break;
          case FrequencyEnum.Enum.ThreeTimesADay:
            this.updateMultiple({
              timeOfDay1: this.defaults[OrgConfig.Enum.Freq_Default_ThreeTimesADay].value,
              timeOfDay2: this.defaults[OrgConfig.Enum.Freq_Default_ThreeTimesADay].value2,
              timeOfDay3: this.defaults[OrgConfig.Enum.Freq_Default_ThreeTimesADay].value3
            });
            break;
        }
      }
    }
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    updateKey(keyValue) {
      this.$emit("input", { ...this.value, [keyValue.key]: keyValue.value });
    },
    updateMultiple(updates) {
      this.$emit("input", { ...this.value, ...updates });
    }
  }
};
</script>
