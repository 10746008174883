<template>
  <div class="timeOfDay">
    <LabelSuffix :suffix="days" v-if="frequency === FrequencyEnum.OneOff">
      <b-numberinput
        expanded
        :value="value.timeOfDay1"
        @input="v => updateTime(v, 'timeOfDay1')"
        placeholder="Due in X Days"
        :controls="false"
        required
        :disabled="disabled"
        min="0"
        max="30"
        class="inputFixedWidth-1"
      />
    </LabelSuffix>

    <EnumDropDown
      v-if="frequency === FrequencyEnum.OnceAMonth"
      :value="value.timeOfDay1"
      @input="v => updateTime(v, 'timeOfDay1')"
      :data="MonthSelect"
      placeholder="When in the month"
      required
      :disabled="disabled"
    />
    <WeekDaySelect
      v-if="frequency === FrequencyEnum.OnceAWeek"
      :value="value.timeOfDay1"
      @input="v => updateTime(v, 'timeOfDay1')"
      :disabled="disabled"
    />
    <div v-if="frequency === FrequencyEnum.TwiceAWeek" class="is-flex">
      <WeekDaySelect :value="value.timeOfDay1" @input="v => updateTime(v, 'timeOfDay1')" :disabled="disabled" />
      <WeekDaySelect :value="value.timeOfDay2" @input="v => updateTime(v, 'timeOfDay2')" :disabled="disabled" />
    </div>
    <HourSelect
      v-if="frequency === FrequencyEnum.OnceADay"
      :value="value.timeOfDay1"
      @input="v => updateTime(v, 'timeOfDay1')"
      :disabled="disabled"
    />
    <div v-if="frequency === FrequencyEnum.TwiceADay" class="is-flex">
      <HourSelect :value="value.timeOfDay1" @input="v => updateTime(v, 'timeOfDay1')" :disabled="disabled" />
      <HourSelect :value="value.timeOfDay2" @input="v => updateTime(v, 'timeOfDay2')" :disabled="disabled" />
    </div>
    <div v-if="frequency === FrequencyEnum.ThreeTimesADay" class="is-flex">
      <HourSelect :value="value.timeOfDay1" @input="v => updateTime(v, 'timeOfDay1')" :disabled="disabled" />
      <HourSelect :value="value.timeOfDay2" @input="v => updateTime(v, 'timeOfDay2')" :disabled="disabled" />
      <HourSelect :value="value.timeOfDay3" @input="v => updateTime(v, 'timeOfDay3')" :disabled="disabled" />
    </div>
  </div>
</template>

<script>
import EnumDropDown from "@/components/EnumDropDown";
import LabelSuffix from "@/components//LabelSuffix";
import FrequencyEnum from "@/enums/frequency.js";
import WeekDaySelect from "./WeekDaySelect.vue";
import HourSelect from "./HourSelect.vue";

export default {
  components: { EnumDropDown, WeekDaySelect, HourSelect, LabelSuffix },
  props: {
    value: {},
    disabled: { type: Boolean },
    frequency: { type: Number }
  },
  computed: {
    days() {
      return this.value.timeOfDay1 === 1 ? "day" : "days";
    }
  },
  methods: {
    updateTime(v, key) {
      this.$emit("input", { key: key, value: v });
    }
  },
  created() {
    // setting in num like this, in created rather than data, means its not reactive and it doesn't need to be
    this.FrequencyEnum = FrequencyEnum.Enum;
    this.MonthSelect = FrequencyEnum.MonthSelect;
  }
};
</script>

<style scoped>
.timeOfDay {
  width: 220px;
}
</style>
