import { GetSelectLookup } from "./common";

const ConsciousLevelEnum = {
  Conscious: 0,
  Unconscious: 1,
  AlteredLevel: 2,
  Confusion: 3,
  Delirium: 4,
  Obtundation: 5,
  Stupor: 6,
  Coma: 7
};

const ConsciousLevelLookup = [
  "Conscious",
  "Unconscious",
  "Altered Level of Consciousness (ALOC)",
  "Confusion",
  "Delirium",
  "Obtundation",
  "Stupor",
  "Coma"
];

const ConsciousLevelLookupShort = ["Conscious", "Unconscious", "Altered", "Confusion", "Delirium", "Obtundation", "Stupor", "Coma"];

export default {
  Enum: ConsciousLevelEnum,
  SelectLookup: GetSelectLookup(ConsciousLevelEnum, ConsciousLevelLookup),
  Lookup: ConsciousLevelLookup,
  LookupShort: ConsciousLevelLookupShort
};
