<template>
  <div class="columns narrow">
    <div class="column is-1">
      <div class="level-item has-text-centered" v-if="i === 0">
        <p class="label">Risks</p>
        <ClickIcon v-if="value.itemType !== itemType.Enum.None" icon="add" title="Add New Risk" @click="$emit('addRisk')" />
        <ClickIcon icon="delete" title="Delete Item" @click="$emit('deletItem')" />
      </div>
    </div>
    <div class="column">
      <EnumDropDown
        :value="value.itemType"
        @input="(v) => update('itemType', v)"
        :data="itemType.ItemTypeLookup"
        required
        :ref="'r' + value.riskInterventionId"
        :selectRef="'r' + value.riskInterventionId"
      />
    </div>
    <div class="column">
      <EnumDropDown
        v-if="value.itemType === itemType.Enum.Assessment"
        :value="value.assessmentType"
        @input="(v) => update('assessmentType', v)"
        :data="assessmentTypeLookUp"
        required
      />
      <EnumDropDown
        v-if="value.itemType === itemType.Enum.Observation"
        :value="value.observationType"
        @input="(v) => update('observationType', v)"
        :data="observationTypeLookUp"
        required
      />
      <EnumDropDown
        v-if="value.itemType === itemType.Enum.Demographics"
        :value="value.fieldGroupType"
        @input="(v) => update('fieldGroupType', v)"
        :data="fieldGroupTypeLookup"
        required
      />
      <!-- Note no fieldGroup for Wounds -->
    </div>
    <div class="column">
      <EnumDropDown
        v-if="!!fieldTypeLookup"
        :value="value.fieldType"
        @input="(v) => update('fieldType', v)"
        :data="fieldTypeLookup"
        required
        :ref="'f' + value.riskInterventionId"
        :selectRef="'f' + value.riskInterventionId"
      />
    </div>
    <div class="column is-1">
      <EnumDropDown
        v-if="!!fieldTypeLookup"
        :value="value.operator"
        @input="(v) => update('operator', v)"
        :data="operatorLookup"
        required
        class="operator"
      />
    </div>
    <div class="column">
      <div v-if="!!fieldTypeLookup">
        <b-numberinput
          v-if="showNumericInt"
          :value="value.fieldValueInt"
          @input="(v) => update('fieldValueInt', v)"
          :controls="false"
          required
        />
        <EnumDropDown v-else :value="value.fieldValueInt" @input="(v) => update('fieldValueInt', v)" :data="fieldValueLookup" required />
      </div>
    </div>
    <div class="level-item has-text-centered">
      <ClickIcon :disabled="i === 0" icon="delete" title="Delete Risk" @click="$emit('deleteRisk')" />
    </div>
  </div>
</template>

<script>
import EnumDropDown from "@/components/EnumDropDown";
import ClickIcon from "@/components/ClickIcon";

import RiskEnum from "@/enums/risk";
import FieldType from "@/enums/fieldType";
import AssessmentType from "@/enums/assessmentType.js";
import ObservationType from "@/enums/observationType.js";
import LookupType from "@/enums/lookups.js";
import IntimateCare from "@/enums/intimateCare.js";
import Cohen from "@/enums/cohen.js";
import Barthel from "@/enums/barthel.js";
import SevereEnum from "@/enums/severe";
import CarePlanEnum from "@/enums/careplan";
import SkinIntegrity from "@/enums/skinIntegrity";
import ConsciousLevel from "@/enums/consciousLevel";
import ManualHandling from "@/enums/manualHandling";
import Cough from "@/enums/cough.js";
import Bowels from "@/enums/bowelEnum.js";
import Limbs from "@/enums/limbMovement.js";
import Gender from "@/enums/gender.js";

export default {
  components: { EnumDropDown, ClickIcon },
  props: {
    i: { type: Number, required: true }, // index
    value: { type: Object, required: true } // {{ itemType, assessmentType, observationType, fieldGroupType, fieldType, operator, fieldValueInt }}
  },
  data() {
    return {
      operatorLookup: FieldType.OperatorLookup,
      itemType: CarePlanEnum,
      assessmentTypeLookUp: AssessmentType.SelectLookup,
      observationTypeLookUp: ObservationType.SelectLookup
    };
  },
  computed: {
    fieldGroupTypeLookup() {
      var lookup = null;
      switch (this.value.itemType) {
        // Asessment & Obs handle with assessmentTypeLookUp and observationTypeLookUp
        case CarePlanEnum.Enum.Demographics:
          lookup = CarePlanEnum.DemographicsLookup;
          break;
      }

      // if only one value, select it
      if (lookup?.length === 1 && this.value.fieldGroupType !== lookup[0].id) this.update("fieldGroupType", lookup[0].id);

      return lookup;
    },
    showNumericInt() {
      return (
        (this.value.itemType === CarePlanEnum.Enum.Assessment &&
          FieldType.InputType[this.value.fieldType] === FieldType.InputTypeEnum.NumericInt &&
          ![AssessmentType.Enum.IntimateCare, AssessmentType.Enum.ManualHandling].includes(this.value.assessmentType)) ||
        (this.value.itemType === CarePlanEnum.Enum.Observation &&
          ![
            ObservationType.Enum.Cough,
            ObservationType.Enum.Consciousness,
            ObservationType.Enum.Bowel,
            ObservationType.Enum.Limbs
          ].includes(this.value.observationType)) ||
        (this.value.itemType === CarePlanEnum.Enum.Demographics &&
          ![CarePlanEnum.PersonalDetailsEnum.Gender].includes(this.value.fieldType))
      );
    },
    // Applies if not numeric field
    fieldValueLookup() {
      switch (this.value.itemType) {
        case CarePlanEnum.Enum.Assessment:
          switch (this.value.assessmentType) {
            case AssessmentType.Enum.IntimateCare: {
              return IntimateCare.SelectLookup;
            }
            case AssessmentType.Enum.ManualHandling: {
              return ManualHandling.CapabilitySelect;
            }
            default:
              switch (AssessmentType.RiskType[this.assessmentType]) {
                case AssessmentType.RiskTypeEnum.Cohen:
                  return Cohen.SelectLookup;
                case AssessmentType.RiskTypeEnum.Barthel:
                  return Barthel.SelectLookup;
                case AssessmentType.RiskTypeEnum.Severe:
                  return SevereEnum.SelectLookup;
                case AssessmentType.RiskTypeEnum.Standard3:
                  return RiskEnum.SelectLookup3;
                default:
                  return RiskEnum.SelectLookup;
              }
          }

        case CarePlanEnum.Enum.Observation:
          switch (this.value.observationType) {
            case ObservationType.Enum.Cough:
              return Cough.SelectLookup;
            case ObservationType.Enum.Consciousness:
              return ConsciousLevel.SelectLookup;
            case ObservationType.Enum.Bowel:
              return Bowels.QuantitySelect;
            case ObservationType.Enum.Limbs:
              return Limbs.ArmSelect;
            default:
              return null;
          }

        case CarePlanEnum.Enum.Demographics:
          switch (this.value.fieldType) {
            case CarePlanEnum.PersonalDetailsEnum.Gender:
              return Gender.SelectLookup;
            default:
              return null;
          }

        case CarePlanEnum.Enum.Wounds:
          switch (this.value.fieldType) {
            case CarePlanEnum.WoundFieldEnum.SkinCondition:
              return SkinIntegrity.SelectLookup;
            default:
              return null;
          }

        default:
          return null;
      }
    },
    fieldTypeLookup() {
      var lookup = null;
      switch (this.value.itemType) {
        case CarePlanEnum.Enum.Assessment:
          switch (this.value.assessmentType) {
            case AssessmentType.Enum.IntimateCare: {
              const lookupInfo = { lookupType: LookupType.Enum.IntimateCare };

              var values = this.$store.getters["lookups/getLookups"](lookupInfo);
              if (!values || !values.length) {
                this.$store.dispatch("lookups/getLookups", lookupInfo);
              }
              lookup = values.map((v) => {
                return { id: v.lookupId, value: v.name };
              });
              break;
            }

            case AssessmentType.Enum.ManualHandling: {
              lookup = ManualHandling.HandlingSelect;
              break;
            }

            default:
              lookup = [
                { id: FieldType.Enum.AssessmentRisk, value: "Risk" },
                { id: FieldType.Enum.AssessmentTotalScore, value: "Total Score" }
              ];
          }
          break;

        case CarePlanEnum.Enum.Observation:
          switch (this.value.observationType) {
            case ObservationType.Enum.BloodPressure:
              lookup = [
                { id: FieldType.Enum.Observation_Numeric, value: "Systolic" },
                { id: FieldType.Enum.Obs_Blood_Diastolic, value: "Diastolic" }
              ];
              break;
            case ObservationType.Enum.SkinIntegrity:
              lookup = [{ id: FieldType.Enum.Observation_Numeric, value: "New Skin Conditions" }];
              break;
            case ObservationType.Enum.Bowel:
              lookup = [{ id: FieldType.Enum.Observation_Numeric, value: "Quantity" }];
              break;
            case ObservationType.Enum.Limbs:
              lookup = [{ id: FieldType.Enum.Observation_Numeric, value: "Left Arm" }];
              break;
            case ObservationType.Enum.Pupils:
              lookup = [{ id: FieldType.Enum.Observation_Numeric, value: "Left Eye" }];
              break;
            default:
              lookup = [{ id: FieldType.Enum.Observation_Numeric, value: ObservationType.Lookup[this.value.observationType] }];
          }
          break;

        case CarePlanEnum.Enum.Demographics:
          switch (this.value.fieldGroupType) {
            case CarePlanEnum.DemographicsEnum.PersonalDetails:
              return CarePlanEnum.PersonalDetailsLookup;
            default:
              return null;
          }

        case CarePlanEnum.Enum.Wounds:
          return CarePlanEnum.WoundsLookup;
      }

      // if only one value, select it
      if (lookup?.length === 1 && this.value.fieldType !== lookup[0].id) this.update("fieldType", lookup[0].id);

      return lookup;
    }
  },

  methods: {
    // Every data update of the component has to $emit a completely new instance of the object which then replaces the object instance stored in the parent's data
    // see https://simonkollross.de/posts/vuejs-using-v-model-with-objects-for-custom-components
    update(key, value) {
      // Numberic input if setting 0 as null, but we may want zero
      if (key === "fieldValueInt" && value === null) value = 0;

      this.$emit("input", { ...this.value, [key]: value });
    }
  }
};
</script>
