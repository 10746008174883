import { GetSelectLookup } from "./common";

const ArmLimbEnum = {
  Normal: 1,
  MildWeak: 2,
  SeverWeak: 3,
  Spastic: 4,
  Extension: 5,
  NoResponse: 6
};

const LegLimbEnum = {
  Normal: 1,
  MildWeak: 2,
  SeverWeak: 3,
  // Spastic: 4,
  Extension: 5,
  NoResponse: 6
};

const LimbLookup = ["", "Normal Power", "Mild Weakness", "Severe Weakness ", "Spastic Flexion", "Extension", "No Response"];
const LimbLookupShort = ["", "Normal", "Mild", "Severe ", "Spastic", "Extension", "None"];

export default {
  ArmEnum: ArmLimbEnum,
  ArmSelect: GetSelectLookup(ArmLimbEnum, LimbLookup),
  Lookup: LimbLookup,
  LookupShort: LimbLookupShort,
  LegEnum: LegLimbEnum,
  LegSelect: GetSelectLookup(LegLimbEnum, LimbLookup)
};
