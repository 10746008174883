import { GetSelectLookup } from "./common";

const ObservationTypeEnum = {
  Height: 1,
  Weight: 2,
  BloodPressure: 3,
  Cough: 4,
  Oxygen: 5,
  Pain: 6,
  Pulse: 7,
  Respiration: 8,
  Temperature: 9,
  SkinIntegrity: 10,
  Consciousness: 11,
  Urine: 12,
  Pupils: 13,
  Bowel: 14,
  BloodSugar: 15,
  ComaScale: 16,
  Limbs: 17
};

const ObservationTypeLookup = [
  "",
  "Height",
  "Weight",
  "Blood Pressure",
  "Cough",
  "Oxygen",
  "Pain Score",
  "Heart Rate / Pulse",
  "Respiration Rate",
  "Temperature",
  "Skin Condition",
  "Level of Consciousness",
  "Urine Output",
  "Pupil Size",
  "Bowel Movement",
  "Blood Sugar",
  "Coma Scale",
  "Limb Movement"
];

const ObservationTypeLookupShort = [
  "",
  "Height",
  "Weight",
  "Blood Pressure",
  "Cough",
  "Oxygen",
  "Pain",
  "Pulse",
  "Respiration",
  "Temperature",
  "Skin",
  "Consciousness",
  "Urine",
  "Pupils",
  "Bowels",
  "Blood Sugar",
  "Coma Scale",
  "Limbs"
];

export default {
  Enum: ObservationTypeEnum,
  SelectLookup: GetSelectLookup(ObservationTypeEnum, ObservationTypeLookup),
  SelectLookupShort: GetSelectLookup(ObservationTypeEnum, ObservationTypeLookupShort),
  Lookup: ObservationTypeLookup,
  Property: {
    height: ObservationTypeEnum.Height,
    weight: ObservationTypeEnum.Weight,
    blood: ObservationTypeEnum.BloodPressure,
    cough: ObservationTypeEnum.Cough,
    oxygen: ObservationTypeEnum.Oxygen,
    pain: ObservationTypeEnum.Pain,
    heart: ObservationTypeEnum.Pulse,
    respiration: ObservationTypeEnum.Respiration,
    temperature: ObservationTypeEnum.Temperature,
    skin: ObservationTypeEnum.SkinIntegrity,
    conscious: ObservationTypeEnum.Consciousness,
    urine: ObservationTypeEnum.Urine,
    pupils: ObservationTypeEnum.Pupils,
    bowel: ObservationTypeEnum.Bowel,
    bloodSugar: ObservationTypeEnum.BloodSugar,
    comaScale: ObservationTypeEnum.ComaScale,
    limbs: ObservationTypeEnum.Limbs
  }
};
