const ItemTypeEnum = {
  None: 0,
  Assessment: 1,
  Observation: 2,
  Demographics: 3,
  Wounds: 4
};

const PersonalDetailsEnum = {
  Age: 1,
  Gender: 2
};

const DemographicsEnum = {
  PersonalDetails: 1
};

const WoundFieldEnum = {
  SkinCondition: 1,
  WoundType: 2
};

export default {
  Enum: ItemTypeEnum,
  ItemTypeLookup: [
    { id: ItemTypeEnum.None, value: "None" },
    { id: ItemTypeEnum.Assessment, value: "Assessment" },
    { id: ItemTypeEnum.Observation, value: "Observation" },
    { id: ItemTypeEnum.Demographics, value: "Demographics" },
    { id: ItemTypeEnum.Wounds, value: "Wounds" }
  ],
  DemographicsEnum: DemographicsEnum,
  DemographicsLookup: [{ id: DemographicsEnum.PersonalDetails, value: "Personal Details" }],
  PersonalDetailsEnum: PersonalDetailsEnum,
  PersonalDetailsLookup: [
    { id: PersonalDetailsEnum.Age, value: "Age" },
    { id: PersonalDetailsEnum.Gender, value: "Gender" }
  ],
  WoundFieldEnum: WoundFieldEnum,
  WoundsLookup: [
    { id: WoundFieldEnum.SkinCondition, value: "Skin Condition" },
    { id: WoundFieldEnum.WoundType, value: "Wound Type" }
  ]
};
