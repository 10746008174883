// import Risk from "@/enums/risk.js"

const FieldTypeEnum = {
  AssessmentRisk: 0,
  AssessmentTotalScore: 1,

  IntimateCare_LookUp: 2, // LookUpId is used as the field type

  Observation_Numeric: 3,
  Obs_Blood_Diastolic: 4,
  ManualHandling: 5 // ManualHandling HandlingTypeEnum
};

const InputTypeEnum = {
  Select: 0,
  NumericInt: 1,
  NumericFloat: 2,
  NumericMoney: 3,
  NumericPercent: 4,
  Date: 5,
  DateTime: 6,
  YesNo: 7,
  YesNoUnsure: 8
};

export default {
  Enum: FieldTypeEnum,

  OperatorLookup: [
    { id: 1, value: "<" },
    { id: 2, value: "<=" },
    { id: 3, value: "=" },
    { id: 4, value: ">=" },
    { id: 5, value: ">" },
    { id: 6, value: "!=" }
  ],
  InputTypeEnum: InputTypeEnum,
  InputType: [
    InputTypeEnum.Select,
    InputTypeEnum.NumericInt,
    InputTypeEnum.Select,
    InputTypeEnum.NumericInt,
    InputTypeEnum.NumericInt,
    InputTypeEnum.NumericInt
  ]
};
