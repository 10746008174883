import { GetSelectLookup } from "./common";

const BowelLookup = [
  "",
  "Type 1: Severe Constipation",
  "Type 2: Mild Constipation",
  "Type 3: Normal",
  "Type 4: Normal",
  "Type 5: Lacking Fibre",
  "Type 6: Mild Diarrhea",
  "Type 7: Severe Diarrhea"
];
const BowelDesc = [
  "",
  "Separate Hard Lumps",
  "Sausage-shaped but Lumpy",
  "Sausage-shaped with cracks on surface",
  "Sausage-snake--shaped, smooth and soft",
  "Soft blobs with clear-cut edges",
  "Fluffy pieces with ragged edges",
  "Watery, no solid pieces"
];

const quantity = {
  None: 0,
  Trace: 1,
  Small: 2,
  Medium: 3,
  Large: 4,
  VeryLarge: 5
};
const quantityLookup = ["None", "Trace", "Small", "Medium", "Large", "Very Large"];

const BowelLookupShort = ["", "Type 1", "Type 2", "Type 3", "Type 4", "Type 5", "Type 6", "Type 7"];

export default {
  SelectLookup: GetSelectLookup(BowelLookup),
  Lookup: BowelLookup,
  Desc: BowelDesc,
  LookupShort: BowelLookupShort,
  QuantityEnum: quantity,
  QuantitySelect: GetSelectLookup(quantity, quantityLookup),
  QuantityLookup: quantityLookup
};
