import { GetSelectLookup } from "./common";

const CompleteTypeEnum = {
  Manual: 1,
  EngagementLevel: 2,
  Assessment: 3,
  Observation: 4
};

const CompleteTypeLookup = ["", "Manual", "With Engagement", "Assessment", "Observation"];

export default {
  Enum: CompleteTypeEnum,
  SelectLookup: GetSelectLookup(CompleteTypeEnum, CompleteTypeLookup),
  Lookup: CompleteTypeLookup
};
