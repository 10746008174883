<template>
  <b-field>
    <EnumDropDown
      :value="value.completionType"
      @input="v => updateCompletion(v, 'completionType')"
      :data="completionType.SelectLookup"
      required
      :disabled="disabled"
    />
    <EnumDropDown
      v-if="value.completionType === completionType.Enum.Assessment"
      :value="value.completionAssessment"
      @input="v => updateCompletion(v, 'completionAssessment')"
      :data="assessmentTypeLookUp"
      required
      :disabled="disabled"
    />
    <EnumDropDown
      v-if="value.completionType === completionType.Enum.Observation"
      :value="value.completionObservation"
      @input="v => updateCompletion(v, 'completionObservation')"
      :data="observationTypeLookUp"
      required
      :disabled="disabled"
    />
  </b-field>
</template>

<script>
import EnumDropDown from "@/components/EnumDropDown";
import CompletionTypeEnum from "@/enums/completionType";
import AssessmentType from "@/enums/assessmentType";
import ObservationType from "@/enums/observationType";

export default {
  components: { EnumDropDown },
  props: {
    value: { type: Object },
    disabled: { type: Boolean }
  },
  data() {
    return {
      completionType: CompletionTypeEnum,
      assessmentTypeLookUp: AssessmentType.SelectLookup,
      observationTypeLookUp: ObservationType.SelectLookupShort
    };
  },
  methods: {
    updateCompletion(v, key) {
      this.$emit("input", { key: key, value: v });
    }
  }
};
</script>
